<template>
  <!-- <Header type="login" /> -->

  <!--  -->
  <div class="layout">
    <div class="container py-5 mt-5">
      <div class="container-inner mt-5">
        <!-- <div class="tabLinks mb-5">
          <button @click="test('t1')" type="button" :class="isActive == 't1' ? 'active' : ''">EMAIL & Passwort</button>
          <button @click="test('t2')" type="button" :class="isActive == 't2' ? 'active' : ''">Monatlicher Betrag</button>
          <button @click="test('t3')" type="button" :class="isActive == 't3' ? 'active' : ''">Bankverbindung</button>
        </div> -->
        <div class="loader-wrapper" v-if="loading">
          <div class="loader"></div>
        </div>
        <div
          v-else-if="registrationError"
          class="row"
        >
          <h1 class="font-primary text-center success-heading mt-5 pt-5">Something went wrong</h1>
          <p v-if="registrationErrorMessage" class="auth-explanation-text">{{registrationErrorMessage}}</p>
        </div>
        <div
          v-else
          :class="
            'row d-flex justify-content-between py-5 tabContent' +
            (isActive == 't1' ? 'active' : '')
          "
        >
          <div class="col-12 d-flex justify-content-center">
            <div
              class="
                d-flex
                flex-column
                align-items-center
                register-step-wrapper
              "
            >
              <h1 class="font-primary mb-4" v-if="!activeStep == 5">
                Create your account
              </h1>
              <div
                :class="{ 'active-step': activeStep == 1 }"
                v-if="activeStep == 1"
              >
                <h3
                  class="
                    mb-4
                    text-center
                    d-flex
                    justify-content-center
                    align-items-center
                  "
                >
                  <img
                    class="me-3"
                    :src="num1Icon"
                    alt=""
                  />Select account type
                </h3>
                <span class="mb-4 d-block text-center auth-explanation-text"
                  >
                  Individual: Registering for personal participation and private use.
                  Corporate: Registering on behalf of and for a companies participation.
                  </span
                >
                <div class="account-types-wrapper d-flex mt-4 pt-2">
                  <div
                    @click="chooseAccountType('individual')"
                    class="
                      me-2
                      account-type
                      personal-account
                      d-flex
                      align-items-center
                      justify-content-center
                      flex-column
                    "
                  >
                    <img
                      class="mb-2"
                      :src="
                        selectedAccountType === 'individual'
                          ? blueHouse
                          : greyHouse
                      "
                      alt=""
                    />
                    <span
                      :class="
                        selectedAccountType === 'individual' ? 'active' : ''
                      "
                      >Individual</span
                    >
                  </div>
                  <div
                    @click="chooseAccountType('corporate')"
                    class="
                      ms-2
                      account-type
                      corporate-account
                      d-flex
                      align-items-center
                      justify-content-center
                      flex-column
                    "
                  >
                    <img
                      class="mb-2"
                      :src="
                        selectedAccountType === 'corporate'
                          ? blueBuilding
                          : greyBuilding
                      "
                      alt=""
                    />
                    <span
                      :class="
                        selectedAccountType === 'corporate' ? 'active' : ''
                      "
                      >Corporate</span
                    >
                  </div>
                </div>
                <button
                  @click="onChooseAccountType(accountType, key)"
                  class="button w-100"
                >
                  Next
                </button>
                <span v-if="invalidStep1" class="registration-error mt-5"
                  >Please select account type before you proceed</span
                >
                <router-link
                  class="login-link text-center d-block mt-5"
                  to="/login"
                  >I already have an account</router-link
                >
                <!-- <div class="row">
                  <div
                    class="col-12 col-md-6"
                    v-for="(accountType, key) in accountTypes"
                    v-bind:key="key"
                  >
                    lol: {{accountType}}
                    <button
                      class="button lg me-2"
                      @click.prevent="onChooseAccountType(accountType, key)"
                    >
                      {{ accountType }}
                    </button>
                  </div>
                </div> -->
              </div>
              <div
                :class="{ 'active-step': activeStep == 2 }"
                v-if="activeStep == 2"
                class="register-step-wrapper"
              >
                <h3
                  class="
                    mb-4
                    text-center
                    d-flex
                    justify-content-center
                    align-items-center
                  "
                >
                  <img class="me-2" :src="num2Icon" alt="" /> Country selection
                </h3>
                <p class="text-center auth-explanation-text">
                  Please select your country from the list of supported
                  countries. If your country is not listed, that means that you
                  are not able to participate.
                </p>
              </div>
              <div
                :class="{ 'active-step': activeStep == 3 }"
                v-if="activeStep == 3"
                class="register-step-wrapper"
              >
                <h3
                  class="mb-4 d-flex align-items-center justify-content-center"
                >
                  <img class="me-2" :src="num3Icon" alt="" /> Login information
                </h3>
                <span class="mb-4 mt-4 auth-explanation-text">Please choose your login credentials which you will use for signing in to Ten31 BLSO platform.</span>
              </div>
              <div
                :class="{ 'active-step': activeStep == 4 }"
                v-if="activeStep == 4"
              >
                <h3
                  class="mb-4 d-flex align-items-center justify-content-center"
                >
                  <img :src="num4Icon" class="me-2" alt="" /> Financial
                  information
                </h3>
                <p class="text-center auth-explanation-text">
                  Submit your SEPA EURO compatible IBAN with your bank’s BIC.  Also submit your PERSONAL Ethereum Address, the Blockchain Listed Shares will be sent there after the offering.  Double check your data before proceeding! 
                </p>
                <!-- <div
                  class="backend-msg"
                  v-for="(errorMsg, index) in errorMessage"
                  :key="index"
                >
                  <span>{{ errorMsg }}</span>
                </div> -->
              </div>
              <div
                class="d-flex flex-column align-items-center"
                :class="{ 'active-step': activeStep == 5 }"
                v-if="activeStep == 5"
              >
                <h2>Registration successful!</h2>
                <img class="mt-3 mb-4" :src="checkIcon" alt="" />
                <p class="text-center auth-explanation-text">
                  Your account has been created, an email has been sent to you for email confirmation. After email confirmation you are able to sign-in with your BLSO account and start your Blockchain Listed Shares journey! Note: KYC can be conducted within your BLSO account, the final step before participation.
                </p>
                <p class="auth-explanation-text">
                  Didn't get your email? <a class="underline" @click="resendEmail" href="#">Resend it</a>
                </p>
              </div>
            </div>

            <!-- <p class="mt-4">
              Zunächst benötigen wir Ihre Mail und ein Passwort.
            </p> -->
          </div>

          <div class="col-12 d-flex justify-content-center">
            <form
              autocomplete="off"
              class="register-form register-step-wrapper"
              v-if="activeStep == 2 || activeStep == 3 || activeStep == 4"
            >
              <div v-if="activeStep == 2">
                <div class="form-group">
                  <label>Country*</label>
                  <select
                    id="country_id"
                    v-model="form.stepTwo.country_id"
                    class="w-100"
                  >
                    <option :selected="true" style="display: none" :value="''">
                      Select your country
                    </option>
                    <template
                      v-for="(country, key) in countries"
                      v-bind:key="key"
                    >
                      <option :value="key">{{ country }}</option>
                    </template>
                  </select>
                </div>
                <p class="validation-error">{{ errors.country_id }}</p>
                <div
                  class="d-flex align-items-center justify-content-center my-5"
                >
                  <!-- <button
                    class="button lg me-5"
                    @click.prevent="onClickPrevious"
                    v-if="activeStep == 2"
                  >
                    Previous
                  </button> -->
                  <button
                    class="button w-100"
                    @click.prevent="onClickNext"
                    v-if="activeStep == 2"
                  >
                    Next
                  </button>
                </div>
                <span class="registration-error mt-5" v-if="invalidStep2"
                  >Please select country before you proceed</span
                >
                <span class="mt-5 stepper-back" @click.prevent="onClickPrevious"
                  >Back to previous step</span
                >
              </div>

              <div v-if="activeStep == 3">
                <div class="form-group">
                  <label>Email*</label>
                  <input
                    v-model="form.stepThree.email"
                    class="customForm"
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                  />
                </div>
                <p class="validation-error">{{ errors.email }}</p>

                <div class="form-group position-relative">
                  <label>Password*</label>
                  <input
                    v-model="form.stepThree.password"
                    class="customForm"
                    type="password"
                    name="password"
                    placeholder="Create your password"
                  />
                  <p class="validation-error">{{ errors.password }}</p>
                  <div tabindex="-1" class="info-wrapper">
                      <div class="info-icon">
                          i
                      </div>
                      <div class="info-box">
                          <p class="mb-0 pe-0">Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number and one special character.</p>
                      </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Confirm password*</label>
                  <input
                    v-model="form.stepThree.confirmPassword"
                    class="customForm"
                    type="password"
                    name="passwordRepeat"
                    placeholder="Confirm your password"
                  />
                </div>
                <p class="validation-error">{{ errors.confirmPassword }}</p>
                <div
                  class="d-flex align-items-center justify-content-center my-5"
                >
                  <!-- <button
                    class="button lg me-5"
                    @click.prevent="onClickPrevious"
                    v-if="activeStep == 3"
                  >
                    Previous
                  </button> -->
                  <button
                    class="button w-100"
                    @click.prevent="onClickNext"
                    v-if="activeStep == 3"
                  >
                    Next
                  </button>
                </div>
                <span class="stepper-back" @click.prevent="onClickPrevious"
                  >Back to previous step</span
                >
              </div>

              <div v-if="activeStep == 4">
                <div class="form-group">
                  <div>
                    <p class="validation-error w-100 text-center" :key="error" v-for="error of errorMessages">
                      {{error.replace('iban', 'IBAN')}}
                    </p>
                  </div>
                  <label>IBAN*</label>
                  <input
                    v-model="form.stepFour.iban"
                    class="customForm"
                    placeholder="Enter your IBAN"
                  />
                </div>
                <p class="validation-error mb-0" v-if="invalidIban">
                  Please enter a valid IBAN
                </p>
                <p class="validation-error mb-0" v-if="ibanEmpty">
                  Please enter your IBAN
                </p>
                <div class="form-group position-relative">
                  <label>BIC*</label>
                  <input
                    @keydown="e => handleBicKeyPress(e)"
                    @input="input => handleBicInput(input)"
                    v-model="form.stepFour.bic_code"
                    class="customForm"
                    placeholder="Enter your BIC"
                  />
                  <div v-if="bicList.length" class="bic-list">
                      <div :class="highlightedBic === index ? 'active' : ''" @click="handleBicSelect(bicItem)" :key="bicItem.bic" v-for="(bicItem, index) in bicList">
                        {{bicItem.bic}}
                      </div>
                    </div>
                </div>
                <p class="validation-error mb-0" v-if="bicIbanCountryMismatch">
                  BIC country does not match IBAN country
                </p>
                <p class="validation-error mb-0" v-else-if="invalidBic && form.stepFour.bic_code.length > 0">
                  Please enter a valid BIC
                </p>
                <p class="validation-error mb-0" v-else-if="bicEmpty">
                  Please enter your BIC
                </p>
                <p class="validation-error mb-0">
                  {{ errors.iban ? errors.iban : "" }}
                </p>
                <div class="form-group">
                  <label>Receiving address*</label>
                  <div class="crypto-address-info-wrapper">
                    <div class="info-icon">
                      i
                    </div>
                    <div class="crypto-info-text">
                      <p>In order to complete the registration we need a wallet address. If you do not have one yet, visit <a class="text-underline" href="https://www.metamask.io">Metamask</a> to get your receiving wallet address. Be aware that this is your private wallet, safekeeping or losing your private key is not our responsibility.</p>
                    </div>
                  </div>
                  <input
                    v-model="form.stepFour.receivingAddress"
                    class="customForm"
                    type="text"
                    name="receiving-address"
                    placeholder="Enter your receiving wallet address"
                  />
                </div>
                <p v-if="errors.receivingAddress" class="validation-error mb-0">{{ errors.receivingAddress }}</p>
                <p class="validation-error mb-0" v-if="noReceivingAddress">Please enter receiving address</p>
                <p class="validation-error mb-0" v-if="invalidEthAddress">Receiving address is not valid</p>
                <div
                  class="d-flex align-items-center justify-content-center my-5"
                >
                  <!-- <button
                    class="button"
                    @click.prevent="onClickPrevious"
                    v-if="activeStep == 4"
                  >
                    Previous
                  </button> -->
                  <button
                    :disabled="disableSubmit"
                    class="button w-100"
                    type="button"
                    @click.prevent="register"
                    v-if="activeStep == 4"
                  >
                    Submit
                  </button>
                </div>
                <span @click.prevent="onClickPrevious" class="stepper-back"
                  >Back to previous step</span
                >
              </div>
              <!-- <div class="form-group mt-4 pt-2">
                <button @click="register" type="button" class="button lg">
                  Submit
                </button>
              </div> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  -->

  <!-- <Footer/> -->
</template>

<script lang="js">
import { defineComponent } from "vue";
import config from "../../config";
import axios from "axios";
import num1Icon from "../../assets/images/num-1.svg";
import num2Icon from "../../assets/images/num-2.svg";
import num3Icon from "../../assets/images/num-3.svg";
import num4Icon from "../../assets/images/num-4.svg";
import checkIcon from "../../assets/images/check-blue.svg";
import blueHouse from "../../assets/images/house-blue.svg";
import greyHouse from "../../assets/images/house-grey.svg";
import blueBuilding from "../../assets/images/building-blue.svg";
import greyBuilding from "../../assets/images/building-grey.svg";
import { ethers } from 'ethers';
import iban from 'iban';

export default defineComponent({
  name: "Register",
  data: function () {
    return {
      bicIbanCountryMismatch: false,
      bicCountryCode: '',
      highlightedBic: null,
      disableSubmit: false,
      invalidBic: null,
      invalidEthAddress: false,
      invalidStep1: false,
      invalidStep2: false,
      ibanEmpty: false,
      bicEmpty: false,
      invalidIban: false,
      isActive: "t1",
      countries: [],
      accountTypes: [],
      blueHouse: blueHouse,
      greyHouse: greyHouse,
      blueBuilding: blueBuilding,
      greyBuilding: greyBuilding,
      num1Icon: num1Icon,
      num2Icon: num2Icon,
      num3Icon: num3Icon,
      num4Icon: num4Icon,
      checkIcon: checkIcon,
      registrationError: false,
      loading: false,
      invalidBtcFormat: false,
      errorMessages: [],
      noReceivingAddress: false,
      bicList: [],
      form: {
        stepTwo: {
          country_id: "",
        },
        stepThree: {
          email: "",
          password: "",
          confirmPassword: "",
        },
        stepFour: {
          iban: "",
          bic_code: "",
          receivingAddress: "",
        },
      },
      selectedAccountType: null,
      errors: {},
      activeStep: 1,
      selectAccountTypeLabel: null,
      selectAccountTypeValue: null,
      errorMessage: [],
      registrationErrorMessage: null
    };
  },
  components: {
    // Autocomplete
  },
  mounted: function () {
    this.getCountries();
  },
  methods: {
    handleBicKeyPress(e) {
      if (!this.bicList.length) {
        return;
      }
      if (e.keyCode === 40) {
        if (this.highlightedBic === null || this.highlightedBic + 1 === this.bicList.length) {
          this.highlightedBic = 0;
        } else if (typeof this.highlightedBic === 'number' && this.highlightedBic + 1 < this.bicList.length) {
          this.highlightedBic++;
        }
      } else if (e.keyCode === 38) {
        if (this.highlightedBic === null || this.highlightedBic === 0) {
          this.highlightedBic = this.bicList.length - 1;
        } else if (typeof this.highlightedBic === 'number' && this.highlightedBic > 0) {
          this.highlightedBic--;
        }
      } else if (e.keyCode === 13) {
        this.handleBicSelect(this.bicList[this.highlightedBic]);
      }
    },
    handleBicSelect(value) {
      console.log('value', value);
      this.bicList = [];
      this.bicCountryCode = value.country_of_registration;
      this.form.stepFour.bic_code = value.bic;
    },
    handleBicInput(e) {
      console.log(e.target.value);
      if (e.target.value.length < 2) {
        return;
      }
      this.getBicList(e.target.value);
    },
    getBicList(input) {
      fetch(`${config.API}/api/v1/bic-suggestions`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          bic: input
        })
      })
      .then(res => res.json())
      .then(data => {
        console.log('bicssssss', data.data.bic_suggestions);
        if (data.error || data.errors) {
          this.$toast.error(data.error);
          return;
        }
        // const bicArray = [];
        // for (const item in data.data.bic_suggestions) {
        //   bicArray.push(item);
        // }
        this.bicList = data.data.bic_suggestions.slice(0, 10);
      })
      .catch(error => {
        console.log(error);
      })
    },
    resendEmail() {
      fetch(`${config.API}/api/email/verify/resend`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: this.form.stepThree.email
        })
      })
      .then(res => res.json())
      .then(data => {
        console.log(data);
        if (data.errors) {
          this.$toast.error(data.errors);
        } else {
          this.$toast.success('Email resent successfully');
        }
      })
      .catch(error => {
        console.log(error);
        this.$toast.error(error);
      });
    },
    chooseAccountType(accType) {
      this.invalidStep1 = false;
      this.selectedAccountType = accType;
    },
    isRequired(value) {
      return value ? true : "This field is required";
    },
    test(tab) {
      console.log(tab);
      this.isActive = tab;
    },
    register() {
      let validationErrors = 0;
      this.bicList = [];
      this.errorMessages = [];
      this.invalidEthAddress = false;
      this.ibanEmpty = false;
      this.invalidIban = false;
      this.bicIbanCountryMismatch = false;
      const tempBic = this.form.stepFour.bic_code;
      if (this.bicCountryCode !== this.form.stepFour.iban.slice(0, 2)) {
        this.bicIbanCountryMismatch = true;
        validationErrors++;
      }
      if (!this.form.stepFour.iban) {
        this.ibanEmpty = true;
        validationErrors++;
      } else {
        this.ibanEmpty = false;
        if (!iban.isValid(this.form.stepFour.iban)) {
          this.invalidIban = true;
          validationErrors++;
        } else {
          this.invalidIban = false;
        }
      }

      if (!this.form.stepFour.bic_code) {
        this.bicEmpty = true;
        validationErrors++;
      } else {
        this.bicEmpty = false;
      }

      if (!this.form.stepFour.receivingAddress) {
        this.noReceivingAddress = true;
        validationErrors++;
      } else {
        this.noReceivingAddress = false;
        try {
          ethers.utils.getAddress(this.form.stepFour.receivingAddress);
          this.invalidEthAddress = false;
        } catch(err) {
          this.invalidEthAddress = true;
          validationErrors++;
        }
      }

      // if (this.form.stepFour.receivingAddress && !this.form.stepFour.receivingAddress.match(/^(bc1|[123])[a-zA-HJ-NP-Z0-9]{25,39}$/g)) {
      //   this.invalidBtcFormat = true;
      //   validationErrors++;
      // } else {
      //   this.invalidBtcFormat = false;
      // }

      if (validationErrors > 0 || this.invalidBic) {
        return;
      }

      this.loading = true;

      fetch(`${config.API}/api/v1/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          bic_code: this.form.stepFour.bic_code,
          country_id: this.form.stepTwo.country_id,
          email: this.form.stepThree.email,
          password: this.form.stepThree.password,
          password_confirmation: this.form.stepThree.confirmPassword,
          iban: this.form.stepFour.iban,
          receiving_address: this.form.stepFour.receivingAddress,
          account_type: this.selectedAccountType,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log('yo', data);
          if (data.errors && data.errors.length) {
            this.errorMessages = data.errors;
            this.form.stepFour.bic_code = tempBic;
          } else {
            this.activeStep++;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.registrationError = true;
          this.loading = false;
        });
    },
    getCountries() {
      fetch(`${config.API}/api/v1/countries`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.countries = data.data.countries;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAccountTypes() {
      axios({
        method: "GET",
        url: `${config.API}/api/v1/account-types`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((res) => {
          this.accountTypes = res.data.data.accountTypes;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onChooseAccountType(label, value) {
      if (!this.selectedAccountType) {
        this.invalidStep1 = true;
        return;
      }
      this.selectAccountTypeLabel = label;
      this.selectAccountTypeValue = value;
      this.activeStep == 1;
      this.activeStep++;
    },

    onClickPrevious() {
      this.activeStep--;
      this.activeStepInd--;
    },
    onClickNext() {
      if (this.activeStep == 2) {
        if (!this.form.stepTwo.country_id) {
          this.invalidStep2 = true;
          return;
        } else {
          this.errors.country_id = null;
          this.activeStep++;
        }
      } else if (this.activeStep == 3) {
        let validationResult = 0;
        for (let item in this.form.stepThree) {
          if (!this.form.stepThree[item]) {
            this.errors[item] = "This field is required";
            validationResult++;
          } else {
            this.errors[item] = null;
          }
          if (item === "email") {
            if (!this.form.stepThree[item]) {
              this.errors[item] = "This field is required";
              validationResult++;
            } else if (!this.form.stepThree[item].match(/\S+@\S+\.\S+/g)) {
              this.errors[item] = "Please enter a valid e-mail address";
              validationResult++;
            }
          }
          if (item === "password") {
            if (!this.form.stepThree[item]) {
              this.errors[item] = "This field is required";
              validationResult++;
            } else if (this.form.stepThree[item].length < 8) {
              this.errors[item] = "Password must be at least 8 characters";
              validationResult++;
            } else if (!this.form.stepThree[item].match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&+_-])[A-Za-z\d@$!%*?&+_-]{8,20}$/)) {
              this.errors[item] = 'Password does not meet requirements';
              validationResult++;
            }
          }
          if (item === "confirmPassword") {
            if (this.form.stepThree[item] !== this.form.stepThree.password) {
              this.errors[item] = "Password confirmation does not match";
              validationResult++;
            }
          }
        }
        if (validationResult > 0) {
          return;
        }
        this.activeStep++;
      } else if (this.activeStep == 4) {
        let validationResult = 0;
        for (let item in this.form.stepFour) {
          if (!this.form.stepFour[item]) {
            this.errors[item] = "This field is required";
            validationResult++;
          } else {
            this.errors[item] = null;
          }
          if (item === "iban") {
            if (!this.form.stepThree[item]) {
              this.errors[item] = "This field is required";
              validationResult++;
            } else if (this.form.stepThree[item].length != 21) {
              this.errors[item] = "Iban must have 21 characters";
              validationResult++;
            }
          }
        }
        if (validationResult > 0) {
          return;
        }
        this.activeStep++;
      }
    },
  },
  watch: {
    "form.stepTwo.country_id": function () {
      this.invalidStep2 = false;
      this.errors.country_id = "";
    },
    "form.stepThree.email": function () {
      this.errors.email = "";
    },
    "form.stepThree.password": function () {
      this.errors.password = "";
    },
    "form.stepThree.confirmPassword": function () {
      this.errors.confirmPassword = "";
    },
    "form.stepFour.iban": function () {
      this.errors.iban = "";
    },
    // "form.stepFour.bic_code": function (val) {
    //   // this.disableSubmit = true;
    //   // this.getBicList(val);
    // },
    "form.stepFour.receivingAddress": function () {
      this.errors.receivingAddress = "";
      this.invalidEthAddress = false;
    },
  },
});
</script>
